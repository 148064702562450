/**
 * Stores build information. The string literals are replaces at compile time by `set_version.js`.
 */
export const buildInfo = {
    versionNumber: "0.1.0",
    versionName: "nightly 2023-06-01",
    versionChannel: "nightly",
    buildDate: "2023-06-01T00:23:10.100Z",
    commitHash: "8b257ef6da5fa5b36c83eed3496c455511649dad",
};
